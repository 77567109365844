






























































import { mediciones_inventario } from "@/shared/dtos/mediciones_inventario";
import { MessageService } from "@/shared/services/message-service";
import mediciones_inventarioModule from "@/store/modules/mediciones_inventario-module";
import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
@Component({
  components: { Xbtn: () => import("@/components/buttons/x-btn.vue") },
  $_veeValidate: { validator: "new" },
})
export default class mediciones_inventarioFormulario extends Vue {
  @Prop({ default: -1 }) id!: number;
  @PropSync("id_inventario", { default: -1 }) _id_inventario!: number;

  created() {
    mediciones_inventarioModule.getmediciones_inventario(this.id);
  }

  public get es_edicion() {
    return this.id > -1;
  }

  public get datasource() {
    if (!this.es_edicion) {
      return new mediciones_inventario();
    }
    return mediciones_inventarioModule.mediciones_inventario;
  }

  public eliminar(obj: mediciones_inventario) {
    MessageService.confirm(
      "¿Seguro que quiere eliminar " + obj.comprobacion + "?",
      "Eliminar",
      (x: boolean) => {
        if (x) {
          mediciones_inventarioModule
            .eliminarmediciones_inventario(obj)
            .then(() => this.cancelar())
            .catch(() => {
              MessageService.toast(
                "No se ha podido eliminar, hay datos asociados a este departamento",
                MessageService.TypeWarning
              );
            });
        }
      }
    );
  }

  /**
   * cancelar
   */
  public cancelar() {
    this.$emit("close");
  }

  /**
   * aceptar
   */
  public aceptar() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        if (this.es_edicion) {
          mediciones_inventarioModule.modificarmediciones_inventario(
            this.datasource
          );
        } else {
          this.datasource.id_inventario = this._id_inventario;
          mediciones_inventarioModule.guardarmediciones_inventario(
            this.datasource
          );
        }
        this.cancelar();
      }
    });
  }
}
